<template>
  <div class="layout-main bg-white">
    <div class="container">
      <h1 class="title fs-h4" v-text="title"></h1>
      <div class="text-block fs-body-14">
        揪山 (Joyhike) 及所屬網域 (https://joyhike.com) 所提供之服務係由揪山股份有限公司提供，以下提及揪山 (Joyhike) 時，同時也代表揪山股份有限公司。 當您開始使用揪山提供的服務（以下稱「服務」）時，代表您同意以下條款，因此請您務必詳讀以下條款。若您為未成年人（或受監護或輔助宣告之人），當您同意本條款並使用服務時，視為您及您的監護人或法定代理人已同意本條款。
      </div>
      <h2 class="sub-title fs-h5">服務</h2>
      <div class="text-block fs-body-14">
        <p>
          服務係指揪山網域中所有網站所提供之線上服務。您應依網站說明以及網站操作介面的範圍容許內使用服務。您同意在法律以及正常使用的限度下使用服務。任何對本服務不正常的使用，包含但不限於人為或透過電腦進行的惡意攻擊、不合理的大量存取、超出正常伺服器負載的請求或企圖進入管理者或非您有權限訪問或操作的頁面，一旦經揪山查證屬實，揪山有權立即終止提供服務，並處置您於揪山保存的所有紀錄。
        </p>
        <p>
          在服務中保存的內容部分並非由揪山所提供，揪山並非這些內容的所有人或著作權人，無法保證這些內容的正確性，亦不會對這些內容承擔法律責任，同時受到ISP業者免責相關規定的保護。
        </p>
        <p>
          揪山有權於任何時間暫時或永久修改或終止服務（或其任何部分）。揪山在終止服務前會盡力通知您，惟揪山不對任何內容丟失以及您因此而遭受的相關損失承擔責任。您同意對於服務所作的任何修改、暫停或終止，揪山對您和任何第三人均無需承擔任何責任。
        </p>
      </div>
      <h2 class="sub-title fs-h5">與第三人網站的連結</h2>
      <div class="text-block fs-body-14">
        <p>
          本服務或協力廠商可能會提供連結至其他網站或網路資源的連結，您可能會因此連結至其他業者經營的網站，但不表示揪山與該等業者有任何關係，其他業者經營的網站均由各該業者自行負責，不屬揪山控制及負責範圍之內，揪山對任何檢索結果或外部連結，不擔保其合適性、可依賴性、即時性、有效性、正確性及完整性，您並同意揪山無須為您連結至非屬於揪山之網站所生之任何損害，負損害賠償之責任。
        </p>
      </div>
      <h2 class="sub-title fs-h5">帳號</h2>
      <div class="text-block fs-body-14">
        <p>
          您僅得以您所註冊的使用者帳號使用本服務，為了保障帳號的安全性，揪山建議您勿將登入資訊提供給其他人。
        </p>
        <p>
          揪山得以社群網站帳號進行註冊，您了解當您以社群網站註冊時，揪山仍以您在社群網站註冊時所使用的 email 帳號確認您的身分，當您於所註冊的社群網站變更 email 時，請一併通知揪山變更帳號權限，揪山對於怠於前述通知的使用異常不負責任。
        </p>
        <p>
          由於服務具有高度專屬性，揪山為了保障帳號的安全與專屬性，將禁止多人共用帳號的行為以保障您的權益，揪山保留限制複數人使用同一帳號的權利，一旦揪山發現有除了您以外的人使用您的帳號時，揪山有權採取限制措施、自行或依您的指示暫時終止提供服務。
        </p>
      </div>
      <h2 class="sub-title fs-h5">隱私權政策</h2>
      <div class="text-block fs-body-14">
        <p>
          揪山會在註冊時蒐集您的 Line ID 之個人資訊，包含登入 Line 所使用的姓名及電子信箱。這些個人資訊僅做為您登入辨識之用，除了受司法機關或其他具有法定權限之機構依法定程序要求之外，揪山並不會在本服務之目的範圍外使用您的個人資料給任何第三人，以確保您的權益保護。
        </p>
        <p>
          為提升本服務品質及體驗，揪山會在本服務目的範圍內紀錄或使用您的個人資料，包括但不限於串接第三方支付業者應提供之必要資訊。揪山並且遵守 Line 的隱私權保護政策，在您使用服務期間內，揪山將嚴格保存您的個人資料，若您預期不再使用服務時，您可以隨時透過揪山的客服信箱要求揪山刪除您的個人資料。
        </p>
      </div>
      <h2 class="sub-title fs-h5">使用者的守法義務及承諾</h2>
      <div class="text-block fs-body-14">
        <p>
          您同意並保證不得利用本服務從事侵害他人權益或違法之行為，包括但不限於：
        </p>
        <ul>
          <li>
            張貼、公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案於本服務上；
          </li>
          <li>
            侵害他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利；
          </li>
          <li>
            違反依法律或契約所應負之保密義務；
          </li>
          <li>
            冒用他人名義使用本服務；
          </li>
          <li>
            上載、張貼、傳輸或散佈任何含有電腦病毒或任何對電腦軟、硬體產生中斷、破壞或限制功能之程式碼之資料；
          </li>
          <li>
            從事不法交易行為或張貼虛假不實、引人犯罪之訊息；
          </li>
          <li>
            販賣槍枝、毒品、禁藥、盜版軟體或其他違禁物；
          </li>
          <li>
            提供賭博資訊或以任何方式引誘他人參與賭博；
          </li>
          <li>
            濫發廣告郵件、垃圾郵件、連鎖信、違法之多層次傳銷訊息等；
          </li>
          <li>
            以任何方法傷害未成年人；
          </li>
          <li>
            偽造訊息來源或以任何方式干擾傳輸來源之認定；
          </li>
          <li>
            干擾或中斷本服務或伺服器或連結本服務之網路，或不遵守連結至本服務之相關需求、程序、政策或規則等，包括但不限於：使用任何設備、軟體或刻意規避揪山之排除自動搜尋之標頭 (robot exclusion headers)；
          </li>
          <li>
            對於恐怖行動提供任何實質支持或資源；
          </li>
          <li>
            追蹤他人或其他干擾他人或為前述目前蒐集或儲存他人之個人資訊；
          </li>
          <li>
            其他揪山有正當理由認為不適當之行為。
          </li>
        </ul>
      </div>
      <h2 class="sub-title fs-h5">責任限制及免責聲明</h2>
      <div class="text-block fs-body-14">
        <p>
          揪山所提供之服務（包含付費加值服務）有可能因為其他協力廠商及相關電信業者網路系統軟硬體設備之故障或失靈而全部或一部暫時無法使用。揪山將盡全力在錯誤發生 24 小時內修復，對於無法修復之事項，揪山對於會員無需承擔任何責任。
        </p>
        <p>
          揪山對於所提供的服務，在任何情況下，均僅依各該服務當時的功能及現況提供使用，對於您個人要求，包括但不限於效率、速度、確實、安全、可靠、完整等，揪山均不負任何明示或默示的擔保或保證。
        </p>
      </div>
      <h2 class="sub-title fs-h5">資訊或建議</h2>
      <div class="text-block fs-body-14">
        <p>
          揪山對於您使用本服務或經由本服務連結之其他網站而取得之資訊或建議（包括但不限於健康、運動、醫療、等方面），不擔保其為完全正確無誤，您在做出任何相關規劃與決定之前，仍應請教專業人員針對您的情況提出意見，以符合您的個別需求。
        </p>
        <p>
          揪山隨時會與其他公司、廠商等第三人（「內容提供者」）合作，由其提供包括新聞、訊息等不同內容供揪山刊登，揪山於刊登時均將註明內容提供者。基於尊重內容提供者之智慧財產權，對其所提供之內容並不做實質之審查或修改，對該等內容之正確真偽亦不負任何責任。對該等內容之正確真偽，您宜自行判斷之。您若認為某些內容涉及侵權或有所不實，請逕向該內容提供者反應意見。
        </p>
      </div>
      <h2 class="sub-title fs-h5">活動或廣告</h2>
      <div class="text-block fs-body-14">
        <p>
          您在本服務中瀏覽到的所有活動、廣告內容、文字與圖片之說明、展示樣品或其他銷售資訊，均由各用戶、廣告商、產品與服務的供應商所設計與提出。其活動，可能帶來疾病、身體傷害、財產損失以及死亡等風險，您對於參加活動與廣告之安全性、正確性以及可信度應自行斟酌與判斷。揪山僅為刊登平台，不對前述活動與廣告負任何擔保責任。
        </p>
      </div>
      <h2 class="sub-title fs-h5">買賣或其他交易行為</h2>
      <div class="text-block fs-body-14">
        <p>
          廠商或個人可能透過本服務或經由本服務連結之其他網站提供商品買賣、服務或其他交易行為。您若因此與該等廠商或個人進行交易，各該買賣或其他合約均僅存在您與各該廠商或個人兩造之間。您應要求各該廠商或個人，就其商品、服務或其他交易標的物之品質、內容、運送、保證事項與瑕疵擔保責任等，事先詳細闡釋與說明。您因前述買賣、服務或其他交易行為所產生之爭執，應向各該廠商或個人尋求救濟或解決之道。
        </p>
        <p>
          揪山聲明絕不介入您與廠商或個人間的任何買賣、服務或其他交易行為，對於您所獲得的商品、服務或其他交易標的物亦不負任何擔保責任。您若係向揪山經營的購物網站進行前述買賣、服務或其他交易行為，雙方權利義務將另行依據該網站之責任規範約定書內容決定之。
        </p>
      </div>
      <h2 class="sub-title fs-h5">會員行為</h2>
      <div class="text-block fs-body-14">
        <p>
          由會員公開張貼或私下傳送的資訊、資料、文字、軟體、音樂、音訊、照片、圖形、視訊、信息或其他資料（以下簡稱「會員內容」），均由「會員內容」提供者自負責任。
        </p>
        <p>
          揪山無法控制經由本服務而張貼之「會員內容」，因此不保證其安全性、正確性、完整性或品質。您了解使用本服務時，可能會接觸到令人不快、不適當、令人厭惡之「會員內容」。在任何情況下，揪山均不為任何「會員內容」負責，包含但不限於任何錯誤或遺漏，以及經由本服務張貼、發送電子郵件或傳送而衍生之任何損失或損害。
        </p>
        <p>
          您了解揪山並未針對「會員內容」事先加以審查，但揪山有權（但無義務）依其自行之考量，拒絕或移除經由本服務提供之任何「會員內容」。在不限制前開規定之前提下，揪山及其指定人有權將有違反本服務條款或法令之虞、或令人厭惡之任何「會員內容」加以移除。您使用任何「會員內容」時，就前開「會員內容」之安全性、正確性、完整性或實用性之情形，您同意必須自行加以評估並承擔所有風險。
        </p>
        <p>
          您了解本服務及本服務所包含之軟體，可能含有使數位資料得以被保護之安全元件，使用該等資料必須依揪山或提供該內容予本服務之內容提供者所定的使用規則，您不得試圖破壞或規避任何內含於本服務之使用規則。任何未經合法授權之重製、發行、散布或公開展示本服務所提供之資料之全部或一部，均被嚴格禁止。
        </p>
      </div>
      <h2 class="sub-title fs-h5">智慧財產權</h2>
      <div class="text-block fs-body-14">
        <p>
          揪山所使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計、會員內容等，均由揪山或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。
        </p>
        <p>
          揪山及其關係企業為行銷宣傳本服務，就本服務相關之商品或服務名稱、圖樣等（以下稱「揪山商標」），依其註冊或使用之狀態，受商標法及公平交易法等之保護，未經揪山事前書面同意，您同意不以任何方式使用揪山商標。
        </p>
      </div>
      <h2 class="sub-title fs-h5">會員對揪山之授權</h2>
      <div class="text-block fs-body-14">
        <p>
          若您無合法權利得授權他人使用、修改、重製、公開播送、改作、散布、發行、公開發表某資料，並將前述權利轉授權第三人，請勿擅自將該資料上載、傳送、輸入或提供予揪山。您所上載、傳送、輸入或提供予揪山之任何資料，其權利仍為您或您的授權人所有；但任何資料一經您上載、傳送、輸入或提供予揪山時，即表示您同意由揪山及其關係企業儲存或管理該資料，並由揪山及其關係企業之搜尋工具進行索引及抓取，並公開刊登、使用於揪山及其關係企業的相關系統網路上，揪山及本服務之目的，進行使用、修改、重製、公開播送、改作、散布、發行、公開發表、公開傳輸、公開上映、翻譯該等資料，並得在此範圍內將前述權利轉授權他人。
        </p>
        <p>
          您並保證揪山及其關係企業使用、修改、重製、公開播送、改作、散布、發行、公開發表、公開傳輸、公開上映、翻譯、轉授權該等資料，不致侵害任何第三人之智慧財產權，否則應對揪山及其關係企業負損害賠償責任。
        </p>
      </div>
      <h2 class="sub-title fs-h5">權益侵害處理</h2>
      <div class="text-block fs-body-14">
        <p>
          揪山尊重他人智慧財產權。若您認揪山網站中之任何網頁內容或會員使用揪山服務時，已侵害您的智慧財產權或相關權益，請立即通知揪山。 如使用本服務有侵權情事者，揪山將終止該會員全部或部分服務。
        </p>
      </div>
      <h2 class="sub-title fs-h5">對揪山之協力及補償</h2>
      <div class="text-block fs-body-14">
        <p>
          因您經由本服務提供、張貼或傳送「會員內容」、使用本服務、與本服務連線、違反本服務條款、或侵害其他人任何權利所衍生或導致任何第三人為請求或主張時，您同意使揪山及其子公司、關係企業、經理人、代理人、受僱人、合夥人及授權人免於任何損害。
        </p>
      </div>
      <h2 class="sub-title fs-h5">服務變更</h2>
      <div class="text-block fs-body-14">
        <p>
          揪山保留隨時修訂、調整或修改本服務條款或加諸新的或額外的條款、條件於使用者，使用者同意上述修訂、調整、修改或新增之條款部分，於法令允許的範圍內，得經揪山公告時，溯及生效。
        </p>
      </div>
      <h2 class="sub-title fs-h5">準據法及管轄</h2>
      <div class="text-block fs-body-14">
        <p>
          本條款之解釋、補充及適用均以中華民國法令為準據法。
        </p>
        <p>
          如會員對於本服務之內容發生疑問或爭議，揪山將盡最大誠意與善意，共同探求合理、雙贏之解決方案。惟如因本條款所產生的爭議無法協調解決而需訴訟時，雙方同意應以台灣台北地方法院為第一審專屬管轄法院。
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Terms',
  data() {
    return {
      title: '使用者條款',
    };
  },
  props: {
  },
  components: {
  },
  computed: {
	},
  watch: {
    
  },
  mounted() {

	},
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/terms-dbUsage.scss";
</style>
